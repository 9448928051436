<template>
    <div class="container">
        <CodeBlock
            type="code"
            title="Global"
            content="
     Define a filter globally before creating the Vue instance:
            "
            codeblock="src/main.js
Vue.filter('capitalize', (value) => {
  if (!value) return 
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})"
        />
    </div>
</template>

<script>
export default {
    beforeCreate() {},
    components: {
        CodeBlock: () =>
            import(
                /* webpackChunkName: "CodeBlock" */ "../globalComponents/codeBlock/CodeBlock.vue"
            ),
    },
};
</script>

<style>
</style>